@import "../../utils/variables.scss";
.menu-left-item-expo {
  border-top: 1px solid #FFFF;
  &:first-child {
    border-top: none;
  }
  padding-top:15px;
  & .expo {
    padding:5px 30px 15px 0;
    position:relative;
    @include typoB;
    &:hover span {
      color:$hover;
    }
    cursor:pointer;
    padding-right:30px;
    & .expo-span {
      width:100%;
      display:inline-block;
    }
    &>div.picto {
      position:absolute;
      right:0;
      top:5px;
      width: 26px;
      height: 26px;
      // & svg {
      //   width:100%;
      //   height:100%;
      // }
    }
    &.active {
      &>div {
        & svg .active-fill {
          fill:$hover;
        }
      }
    }
    &:hover {
      &>div {
        & svg .to-stroke {
          stroke:$hover;
        }
      }
    }
    &>div.infos {
      & a {
        text-decoration: none;
        color:#FFF;
        &:hover {
          color:$hover;
        }
      }
      @include typoD;
    }
  }
  &  .item-type {
    @include typoE;
    width:100%;
  }
}
