@import "../../../utils/variables.scss";
.images-player {
  margin-top:1px;
  width:100%;
  padding-bottom:56.25%;
  position: relative;
  .controls {
    position:absolute;
    bottom:0;
    width:100%;
    left:0;
    z-index:100;
    display:flex;
    justify-content: center;
  }
  &>div.swiper-container {
    position: absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
  }
  & .swiper-slide img {
    position: absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    object-fit: contain;
  }
  & .swiper-button-prev, & .swiper-button-next {
    color: $hover;
    &:after {
      text-shadow: 0px 0px 10px #0008;
    }
  }
  & .swiper-button-prev.swiper-button-disabled, .swiper-button-next.swiper-button-disabled {
    opacity:1;
    color: #FFF;
  }
  & .swiper-pagination-bullet {
    opacity:1;
    background-color: #FFF;
    box-shadow: 0 0 30px #0008;
  }
  & .swiper-pagination-bullet-active {
    background-color: $hover;
  }
  & .swiper-button-prev, & .swiper-button-next, & .swiper-pagination, & .swiper-button-prev.swiper-button-disabled, .swiper-button-next.swiper-button-disabled {
    opacity:0;
    transition: opacity 0.5s;
  }
  &:hover {
      & .swiper-button-prev, & .swiper-button-next, & .swiper-pagination, & .swiper-button-prev.swiper-button-disabled, .swiper-button-next.swiper-button-disabled {
        opacity:1;
      }
  }
  .images-player-compteur {
    position:absolute;
    top:5px;
    right:5px;
    cursor:pointer;
    border-radius: 4px;
    color:#FFF;
    text-shadow: 0 0 10px #000;
    padding:5px;
    z-index:10;
    user-select: none;
    @include typoF;
  }
}
