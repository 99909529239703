@import "../../utils/variables.scss";
.rechercher {
    width:100%;
    position:relative;
    bottom:-21px;
    & .MuiGrid-item {
      position: relative;
    }
    & .MuiIconButton-root {
      position:absolute;
      bottom:5px;
      left:0;
      padding:0;
      color:#FFF;
    }
    & .MuiInput-input {
      @include typoD;
      height: 1.1876em;
      margin: 0;
      display: block;
      padding: 6px 0 7px;
    }
    &.xs {
      width:0%;
    }
    &.open {
      width:100%;
    }
    & .MuiFormLabel-root {
        transition: opacity 200ms, color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
        @include typoD;
    }
    & .MuiInputLabel-shrink {
        transform: translate(0, 8px) scale(0.75);
        opacity:0;
    }
    & .MuiInput-underline:after {
      left: 0;
      right: 0;
      bottom: 0;
      content: "";
      position: absolute;
      transform: scaleX(0);
      transform-origin: right;
      transition: transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
      border-bottom: 1px solid #FFF;
      pointer-events: none;
    }
    & .MuiInput-underline.Mui-focused:after {
      transform-origin: left;
      transform: scaleX(1);
    }
    & .MuiInput-underline.Mui-error:after {
      transform: scaleX(1);
      border-bottom-color: #f44336;
    }
    & .MuiInput-underline:before {
      left: 0;
      right: 0;
      bottom: 0;
      content: "\00a0";
      position: absolute;
      transform: scaleX(0);
      transform-origin: right;
      transition: transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
      border-bottom: 1px solid #FFF;
      pointer-events: none;
    }
    & .MuiInput-underline:hover:not(.Mui-disabled):before {
      transform-origin: left;
      border-bottom: 1px solid #FFF;
      transform: scaleX(1);
    }
    & .MuiInput-underline.Mui-disabled:before {
      border-bottom-style: dotted;
    }
    @media (hover: none) {
      & .MuiInput-underline:hover:not(.Mui-disabled):before {
        transform-origin: left;
        transform: scaleX(1);
      }
    }
    .xs & {
      bottom:-3px;
      & .MuiIconButton-root {
        bottom:3px;
        & svg {
          width:20px;
        }
      }
      & .MuiFormLabel-root {
          display:none;
      }
      & .MuiInput-underline:before {
        border:none;
      }
      & .MuiInput-underline:hover:before {
        border:none;
      }
      & .MuiInput-underline:after {
        border:none;
      }
      & .MuiInput-underline.Mui-focused:after {
        border:none;
      }
      & .MuiInput-underline.Mui-error:after {
        border:none;
      }
      & .MuiInput-underline:before {
        border:none;
      }
      & .MuiInput-underline:hover:not(.Mui-disabled):before {
        border:none;
      }
      & .MuiInput-underline.Mui-disabled:before {
        border:none;
      }
      @media (hover: none) {
        & .MuiInput-underline:hover:not(.Mui-disabled):before {
          border:none;
        }
      }
    }
}
