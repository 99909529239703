.audio-player {
  margin-top:1px;
  width:100%;
  padding-bottom:56.25%;
  position: relative;
  &>div {
    position: absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    &>iframe {
      position: absolute;
      top:0;
      left:0;
      width:100%;
      height:100%;
    }
  }
}
