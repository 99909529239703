@import "../../utils/variables.scss";
.lang-switch {
    position:absolute;
    bottom:-8px;
    &.left {
        left:0;
    }
    &.right {
        right:0;
    }
    .xs & {
        bottom:10px;
        right:5px;
    }
    & span {
      display:inline-block;
      text-align:center;
      width:2em;
      text-transform: uppercase;
      font-size: 13px;
      line-height: 17px;
      font-weight: 800;
      cursor: pointer;
      @include typoE;
      &.active {
        @include typoF;
      }
    }
}
