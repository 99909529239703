@import "../../utils/variables.scss";
.main-list {
  & a {
    text-decoration: none;
    color:inherit;
  }
  .main-list-item {
    position: relative;
    padding-bottom: 15px;
    border-top: 1px solid #FFFF;
    &.first {
      border-top: none;
    }
    & .audio-item-label {
      @include typoC;
      padding: 10px 0;
    }
    & .audio-item-value {
      @include typoA;
    }
    & .audio-item-icon {
      position:absolute;
      right:0;
      top:10px;
      width: 26px;
      height: 26px;
      & svg {
        width:100%;
        height:100%;
      }
    }
    &:hover {
      color:$hover;
      border-color:$hover;
      & svg .to-fill {
        fill:$hover;
      }
      & svg .to-stroke {
        stroke:$hover;
      }
    }
  }
  .spacer {
    height:10px;
  }
}
