@import "../../utils/variables.scss";
.mask {
  position:fixed;
  top:0;
  left:0;
  width:100%;
  height:100%;
  background-color: #000;
  z-index:1000;
}
