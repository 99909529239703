@import "../../utils/variables.scss";
.suggestion {
    margin-top: 50px;
    padding-bottom: 15px;
    border-top: 1px solid #FFFF;
    // & span {
    //   display:inline-block;
    //   text-align:center;
    //   width:2em;
    //   text-transform: uppercase;
    //   font-size: 13px;
    //   line-height: 17px;
    //   font-weight: 800;
    //   cursor: pointer;
    //   @include typoE;
    //   &.active {
    //     @include typoF;
    //   }
    // }
    .suggestion-items {
      & a {
        text-decoration: none;
        color:inherit;
      }
      margin-top:30px;
      .cartouche-infos {
        padding-bottom: 15px;
        border-top: 1px solid #FFFF;
        &:hover {
          color:$hover;
          border-color:$hover;
          & svg .to-fill {
            fill:$hover;
          }
          & svg .to-stroke {
            stroke:$hover;
          }
        }
      }
    }
}
