@import "../../utils/variables.scss";
.menu-left {
  width:100%;
  position: sticky;
  top:60px;
  // transition: top 0.5s;
  padding-bottom:30px;
  & .menu-left-surtitre {
    margin-top: 30px;
    @include typoE;
  }
  & .menu-left-item {
    cursor: pointer;
    @include typoB;
    border-top: 1px solid #FFFF;
    &:first-child {
      border-top: none;
    }
    padding:15px 30px 15px 0;
    position: relative;
    &>div.infos {
      & a {
        text-decoration: none;
        color:#FFF;
        &:hover {
          color:$hover;
        }
      }
      @include typoF;
    }
    &>div.picto {
      position:absolute;
      right:0;
      top:12px;
      width: 26px;
      height: 26px;
      // & svg {
      //   width:100%;
      //   height:100%;
      // }
    }
    &:hover {
      color:$hover;
      &>div {
        border-color:$hover;
        & svg .to-fill {
          fill:$hover;
        }
        & svg .to-stroke {
          stroke:$hover;
        }
      }
    }
    &.active {
      &>div {
        & svg .active-fill {
          fill:$hover;
        }
      }
    }
  }
  // &.mini {
  //   top:50px;
  // }
  & .filtres {
    & .MuiGrid-item {
      position: relative;
    }
    & .MuiIconButton-root {
      position:absolute;
      top:-2px;
      left:0;
      padding:0;
    }
  }
}
