@import "../../utils/variables.scss";
.topbar {
  position: sticky;
  z-index:100;
  top:-84px;
  width:100%;
  background-color:#000;
  height:144px;
  & .topbar-inner {
    position:absolute;
    top:0;
    left:140px;
    width: calc( 100% - 280px );
    @include xl {
        left:50px;
        width: calc( 100% - 100px );
    }
    @include lg {
        left:15px;
        width: calc( 100% - 30px );
    }
    height:100%;
    border-bottom: 1px solid #FFFF;
    &>div {
        position:sticky;
        top:-30px;
        left:0;
        width:100%;
        height:72px;
        &>div {
          height:100%;
          &>div {
            height:100%;
          }
        }
        & a {
          text-decoration: none;
          color: inherit;
          &.topbar-mainlink {
            position:absolute;
            bottom:0;
            font-size:35px;
            line-height: 36px;
            font-weight:800;
          }
          &.playlist-link {
            left:0;
          }
          &:hover {
            color:$hover;
          }
          &.front-link {
            right:8px;
            bottom:-18px;
            padding:10px 0;
            &:hover {
              padding:10px;
              color:#000;
              background-color:#FFF;
            }
          }
        }
    }
    &>div.xs {
        top:0;
        height:144px;
        & .xs-title {
            position:sticky;
            top:40px;
        }
        & .xs-utils {

        }
    }
  }
}
.topbar .topbar-inner > div.xs > div {
    height:unset;
}
