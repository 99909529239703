@import "../../utils/variables.scss";
.cartouche-infos{
  position: relative;
  padding-right:15px;
  &.splitstat {
      padding-top:10px;
  }
  & .cartouche-infos-label {
    @include typoE;
    padding-top: 5px;
  }
  & .cartouche-infos-value {
    @include typoB;
    line-height:40px;
    margin-bottom:8px;
    width:100%;
    & a {
      text-decoration: none;
      color:#FFF;
    }
  }
  & .cartouche-infos-texte {
    @include typoD;
    margin:30px 0;
  }
  & .cartouche-infos-ps {
    @include typoE;
    margin:30px 0;
  }
  & .cartouche-infos-icon {
    position:absolute;
    right:0;
    top:17px;
    width: 26px;
    height: 26px;
    // & svg {
    //   width:100%;
    //   height:100%;
    // }
  }
  &.splitstat {
    & .cartouche-infos-value {
      white-space: nowrap;
      overflow:hidden;
      text-overflow: ellipsis;
    }
  }
}
