@import "../../utils/variables.scss";
.fullscreen-btn {
  position:absolute;
  bottom:5px;
  right:5px;
  cursor:pointer;
  padding:5px;
  z-index:10;
  user-select: none;
  z-index:101;
  svg {
    filter: drop-shadow(0 0 7px #000);  }
}
