@import "../../utils/variables.scss";
.visuel {
  padding-bottom:56.25%;
  position:relative;
  & img {
    position:absolute;
    top:0;
    left:0;
    width:100%;
  }
}
