:root {
  --background-color: #299DD8;
}
$hover: var(--hover-color);
// Small tablets and large smartphones (landscape view)
$screen-sm-min: 576px;

// Small tablets (portrait view)
$screen-md-min: 768px;

// Tablets and small desktops
$screen-lg-min: 992px;

// Large tablets and desktops
$screen-xl-min: 1250px;

// Large tablets and desktops
$screen-xxl-min: 1700px;

// XSmall devices
@mixin xs {
   @media (max-width: #{$screen-sm-min}) {
       @content;
   }
}

// Small devices
@mixin sm {
    @media (max-width: #{$screen-md-min}) {
        @content;
    }
}

// Medium devices
@mixin md {
    @media (max-width: #{$screen-lg-min}) {
        @content;
    }
}

// Large devices
@mixin lg {
    @media (max-width: #{$screen-xl-min}) {
       @content;
    }
}

// Extra large devices
@mixin xl {
    @media (max-width: #{$screen-xxl-min}) {
       @content;
    }
}
@mixin typoA {
  font-size:70px;
  line-height: 64px;
  @include xl {
    font-size:56px;
    line-height: 51.2px;
  }
  @include lg {
    font-size:42px;
    line-height: 38.4px;
  }
  font-weight: 800;
  font-family:"neue-haas-grotesk-display";
}
@mixin typoB {
  font-size:35px;
  line-height: 36px;
  @include xl {
    font-size:28px;
    line-height: 28.8px;
  }
  @include lg {
    font-size:21px;
    line-height: 21.6px;
  }
  font-weight: 800;
  font-family:"neue-haas-grotesk-display";
}
@mixin typoC {
  font-size: 17.5px;
  line-height: 22px;
  @include xl {
    font-size:14px;
    line-height: 17.6px;
  }
  font-weight: 800;
  font-family:"neue-haas-grotesk-text";
}
@mixin typoD {
  font-size: 17.5px;
  line-height: 22px;
  @include xl {
    font-size:14px;
    line-height: 17.6px;
  }
  font-weight: 500;
  font-family:"neue-haas-grotesk-text";
}
@mixin typoE {
  font-size: 13px;
  line-height: 17px;
  @include xl {
    font-size:10.4px;
    line-height: 13.6px;
  }
  font-weight: 500;
  font-family:"neue-haas-grotesk-text";
}
@mixin typoF {
  font-size: 13px;
  line-height: 17px;
  @include xl {
    font-size:10.4px;
    line-height: 13.6px;
  }
  font-weight: 800;
  font-family:"neue-haas-grotesk-text";
}
