@import "../../utils/variables.scss";
.footer {
  position:relative;
  width:100%;
  padding: 10px 0 100px 0;
  background-color:#FFF;
  color:#000;
  & a {
      text-decoration: none;
      color:$hover;
  }
  & .footer-inner {
    @include typoE;
    & p:first-child {
      margin-top:0;
    }
    & p:last-child {
      margin-bottom:0;
    }
    margin-left:140px;
    width: calc( 100% - 280px );
    @include xl {
        margin-left:50px;
        width: calc( 100% - 100px );
    }
    @include lg {
        margin-left:15px;
        width: calc( 100% - 30px );
    }
  }
  & .footer-baseline {
      display:inline-block;
      padding:5px;
  }
  &.clone .footer-baseline {
      cursor:pointer;
      &:hover {
          color:$hover;
          background:#000;
      }
  }
  & .footer-plus {
    @include typoD;
    margin-top:30px;
    border-top:1px solid #000;
    padding-top:15px;
    & h3 {
      @include typoB;
      margin:0;
    }
  }
  &.clone {
    display:none;
  }
  &.clone.show {
    display:block;
    position:fixed;
    top:calc(100vh);
    left:0;
    z-index:100;
  }
  &.clone.anim {
    transition: top 0.2s;
  }
  &.clone.top {
    top:calc(100vh - 47px);
  }
}
