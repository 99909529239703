@import "../../utils/variables.scss";
.pagination {
    @include typoF;
    padding: 10px 0;
    & span{
      cursor:pointer;
      &:hover {
        color:$hover;
      }
    }
    & span.chevron {
      margin:0 5px;
    }
    & span.page {
      &:hover {
        color:#FFF;
      }
      cursor:pointer;
      display:inline-block;
      min-width:6em;
      text-align:center;
    }
    & .pagination-right {
      float:right;
    }
}
