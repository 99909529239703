@import "../../utils/variables.scss";
.pdf-player {
  user-select: none;
  margin-top:1px;
  width:100%;
  padding-bottom:56.25%;
  position: relative;
  &>div {
    position:absolute;
    width:100%;
    height:100%;
    top:0;
    left:0;
    overflow:hidden;
    display:flex;
    justify-content: center;
    align-items: center;
  }
  canvas {
    opacity:0;
    transition:opacity 200ms;
    touch-action: none;
    &.ready {
      opacity:1;
    }
  }
  .pager-container {
    position:absolute;
    left:0;
    bottom:0;
    width:100%;
    display:flex;
    justify-content: center;
    align-items: center;
    li {
      display:inline-block;
      margin:0 15px;
      button {
        border:none;
        background:none;
        color:#FFF;
        font-size:3em;
        text-shadow: 0 0 7px #000;
        &:disabled {
          opacity:0;
        }
      }
    }
  }
}
