@import "./utils/variables.scss";
.main-container {
    margin: 0 140px;
    @include xl {
      margin: 0 50px;
    }
    @include lg {
      margin: 0 15px;
    }
    padding-top:0;
    min-height:calc(100vh - 191px);
    & a:hover {
      color:$hover;
    }
}
